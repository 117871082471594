import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import { CiBookmarkPlus } from "react-icons/ci";
import { FiThumbsUp } from "react-icons/fi";
import { TbMessageCircle } from "react-icons/tb";
import { TooltipComponent } from "../../../shared/components/tooltip/Tooltip";
import { formatDateBlog } from "../../../shared/utils/formatDate";
import { IBlog } from "../../types/blog.interface";

interface Props {
  blog: IBlog;
  handleReadMore?: (blog: IBlog) => void;
  selectedCategory: string;
}

const BlogCard: React.FC<Props> = ({
  blog,
  handleReadMore,
  selectedCategory,
}) => {
  const tooltipText: { [key: string]: string } = {
    "0": "Hit like",
    "1": "Add comment",
  };
  const [content, setContent] = useState("");
  const [id, setId] = useState("");
  const sanitizer = DOMPurify.sanitize;
  const sanitizedContent = sanitizer(blog?.content);
  // const contentWithoutImages = sanitizedContent.replace(/<img[^>]*>/g, '');

  useEffect(() => {
    const cleanedContent = sanitizedContent.replace(/\<img[^>]*\>/g, "");
    setContent(cleanedContent);
  }, [sanitizedContent]);

  return (
    <div
      className="col-span-12 md:col-span-6 lg:col-span-4 border border-secondaryVariant2 rounded-2xl p-2 md:p-6 hover:shadow-blogCardShadow relative h-[524px] cursor-pointer"
      onClick={() => {
        handleReadMore && handleReadMore(blog);
      }}
    >
      <img
        className="mb-5 h-56 w-full rounded-xl object-cover"
        src={blog?.imageUrl}
        alt="blog"
      />
      <div className="flex items-center justify-between mb-2 md:mb-3.5">
        <span className="bg-[#FFE6D8] py-2.5 px-5 rounded-full text-sm text-primary font-medium capitalize">
          {selectedCategory}
        </span>
      </div>
      <h3 className="text-2xl font-semibold text-secondary leading-[29px] mb-2 md:mb-3.5 min-h-[58px] line-clamp-2">
        {blog?.title}
      </h3>
      <div
        className="line-clamp-3 max-w-[400px] text-lg text-secondaryVariant mb-8"
        dangerouslySetInnerHTML={{ __html: sanitizer(content) }}
      />
      <div className="absolute bottom-0 md:bottom-[3px] left-0 right-0 p-2 md:px-6">
        <button className="text-statusColor text-lg font-medium mb-4 md:mb-0 xl:mb-1 hover:text-primary">
          Read more...
        </button>
      </div>
      <TooltipComponent id={id} content={tooltipText[id]} />
    </div>
  );
};

export default BlogCard;

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import React, { useEffect, useState } from "react";
import { HiChevronDown } from "react-icons/hi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { RootState } from "../../../../store/store";
import BeforeLoginModal from "../../../proffer_module/components/Modals/BeforeLogin";
import { formatDateAndTime } from "../../../shared/utils/formatDate";
import { showErrorToast } from "../../../shared/utils/toast";
import { medEvalRoutes } from "../../routes/routes";
import { medicalEvalSvc } from "../../services/medEvals.service";
import Calendar from "./Calendar";
import { LuUser } from "react-icons/lu";
import { LuHeartPulse } from "react-icons/lu";
import { TbCalendarHeart } from "react-icons/tb";
import { CiCalendar } from "react-icons/ci";
import { CiSquarePlus } from "react-icons/ci";
import { FiMail } from "react-icons/fi";
import { initBookAppointmentError } from "../../constants/initialForms";
import { regexPatterns } from "../../../shared/constants/regexPatterns";
import { ERR_MSGS } from "../../../shared/constants/errorMsgs";
import { CiCircleMinus } from "react-icons/ci";
import { Service } from "../../enums/service.enum";

interface TeacherInfo {
  name: string;
  email: string;
  state: string;
  service: string;
  dateTime: string;
}

const CenterBookingSelector: React.FC = () => {
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [teachers, setTeachers] = useState<TeacherInfo[]>([
    { name: "", email: "", state: "", service: "", dateTime: "" },
  ]);
  const [bookAppointmentFormErrors, setBookAppointmentFormError] = useState([
    initBookAppointmentError,
  ]);

  const [stateOptions, setStateOptions] = useState<
    { value: string; label: string }[]
  >([{ value: "Maryland", label: "Maryland" }]);
  const [showBeforeLoginModal, setShowBeforeLoginModal] = useState(false);
  const [unavailableDateTimes, setUnavailableDateTimes] = useState<string[]>(
    []
  );
  const [isOpenArray, setIsOpenArray] = useState<boolean[]>([]);
  const { User } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  const serviceOptions = [
    { value: Service.MEDICAL_EVALUATION, label: "Medical Evaluation" },
  ];

  const handleInputChange = (
    index: number,
    field: keyof TeacherInfo,
    value: string
  ) => {
    const updatedTeachers = [...teachers];
    updatedTeachers[index] = { ...updatedTeachers[index], [field]: value };
    setTeachers(updatedTeachers);

    if (field === "dateTime") {
      updateUnavailableDateTimes(updatedTeachers);
    }
  };

  const handleFocus = (
    e: React.FocusEvent<HTMLInputElement, Element>,
    index: number
  ) => {
    const { name } = e.target;
    setBookAppointmentFormError((prev) => {
      const updatedErrors = [...prev];
      updatedErrors[index] = { ...updatedErrors[index], [`${name}Error`]: "" };
      return updatedErrors;
    });
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement, Element>,
    index: number
  ) => {
    const { name, value } = e.target;

    setBookAppointmentFormError((prev) => {
      const updatedErrors = [...prev];
      if (name === "name" && !regexPatterns.name.test(value)) {
        updatedErrors[index] = {
          ...updatedErrors[index],
          nameError: ERR_MSGS.Name_length,
        };
      } else if (name === "email" && !regexPatterns.email.test(value)) {
        updatedErrors[index] = {
          ...updatedErrors[index],
          emailError: ERR_MSGS.Invalid_Email,
        };
      }
      return updatedErrors;
    });
  };
  const updateUnavailableDateTimes = (updatedTeachers: TeacherInfo[]) => {
    const unavailable = updatedTeachers
      .map((teacher) => teacher.dateTime)
      .filter((dateTime) => dateTime !== "");
    setUnavailableDateTimes(unavailable);
  };

  const handleToggleCalendar = (index: number, isOpen: boolean) => {
    const newIsOpenArray = [...isOpenArray];
    newIsOpenArray[index] = isOpen;
    setIsOpenArray(newIsOpenArray);
  };

  const handleDateTimeSelection = (
    index: number,
    date: Date,
    time?: string
  ) => {
    const dateTimeString = `${date.toDateString()}/${time || ""}`;
    handleInputChange(index, "dateTime", dateTimeString);
    if (time) {
      setIsOpenArray((prev) =>
        prev.map((isOpen, i) => (i === index ? false : isOpen))
      );
    }
  };

  const handleAddTeacher = () => {
    setTeachers([
      ...teachers,
      { name: "", email: "", state: "", service: "", dateTime: "" },
    ]);
    setBookAppointmentFormError([
      ...bookAppointmentFormErrors,
      initBookAppointmentError,
    ]);
  };

  const handleRemoveTeacher = (index: number) => {
    const updatedTeachers = [...teachers];
    updatedTeachers.splice(index, 1);

    setTeachers(updatedTeachers);

    const updatedErrors = [...bookAppointmentFormErrors];
    updatedErrors.splice(index, 1);
    setBookAppointmentFormError(updatedErrors);
  };

  const handleOpenBeforeLoginModal = () => {
    setShowBeforeLoginModal(true);
    localStorage.setItem("filledInfo", JSON.stringify(teachers));
  };

  const handleCloseBeforeLoginModal = () => {
    setShowBeforeLoginModal(false);
    localStorage.removeItem("filledInfo");
  };

  const handleBookAppointment = () => {
    console.log("teachers", teachers);
    if (!User?._id) {
      handleOpenBeforeLoginModal();
    } else if (!User?.name) {
      showErrorToast(
        "Please complete your profile information in edit profile to Book your appointment"
      );
    } else {
      handleBooking();
    }
  };

  const handleBooking = async () => {
    let allValid = true;
    const validatedTeachers = [];

    for (const teacher of teachers) {
      const formattedDateTime = formatDateAndTime(teacher.dateTime);
      try {
        const resp = await medicalEvalSvc.validateSelections(
          teacher.state,
          teacher.service,
          formattedDateTime
        );
        if (resp?.validate === true) {
          validatedTeachers.push({
            service: teacher.service,
            state: teacher.state,
            date: formattedDateTime.date,
            timeSlot: formattedDateTime.timeSlot,
            teacherName: teacher.name,
            teacherEmail: teacher.email,
          });
        } else {
          allValid = false;
          showErrorToast(`Validation failed for ${teacher.name}`);
          break;
        }
      } catch (error) {
        allValid = false;
        showErrorToast(`Error validating appointment for ${teacher.name}`);
        console.error(error);
        break;
      }
    }

    if (allValid) {
      navigate(medEvalRoutes?.confirm_booking, {
        state: {
          teachers: validatedTeachers,
          isCenter: true,
        },
      });
    }
  };

  // const isFormComplete = teachers.every(
  //   (teacher) =>
  //     teacher.name &&
  //     teacher.email &&
  //     teacher.state &&
  //     teacher.service &&
  //     teacher.dateTime
  // );

  useEffect(() => {
    if (!localStorage.getItem("filledInfo")) return;
    const temp = JSON.parse(localStorage.getItem("filledInfo")!);
    setTeachers(temp);
    if (User?._id) localStorage.removeItem("filledInfo");
  }, [localStorage.getItem("filledInfo")]);


  useEffect(() => {
    const formComplete = teachers.every(
      (teacher) =>
        teacher.name &&
        teacher.email &&
        teacher.state &&
        teacher.service &&
        teacher.dateTime
    );
    setIsFormComplete(formComplete);
  }, [teachers]);


  useEffect(() => {
  const isAnyOpen = isOpenArray.some((openState) => openState);

  if (isAnyOpen) {
    document.body.classList.add("hideScrollEvent");
    document.body.style.pointerEvents = "none";
  } else {
    document.body.classList.remove("hideScrollEvent");
    document.body.style.pointerEvents = "auto";
  }

  return () => {
    document.body.classList.remove("hideScrollEvent");
    document.body.style.pointerEvents = "auto";
  };
}, [isOpenArray]);

  return (
    <>
      <div className="py-6 md:py-10 lg:py-16 max-w-[744px] mx-auto">
        <h2 className="text-2xl md:text-xll text-secondary mb-7 font-semibold">
          Booking as a Center for Your Teachers{" "}
          <h5 className="text-[15px] text-secondary mb-7">
            Available Tuesdays and Thursdays.{" "}
          </h5>
        </h2>

        {teachers?.map((teacher, index) => (
          <div key={index} className="grid grid-cols-12 gap-4 mb-9">
            <div className="col-span-12 sm:col-span-6 relative">
              <input
                type="text"
                className="ps-9 pe-4 py-3.5 rounded-lg border-secondaryVariant2 border w-full"
                placeholder="Teacher Name"
                value={teacher.name}
                name="name"
                onChange={(e) =>
                  handleInputChange(index, "name", e.target.value)
                }
                onFocus={(e) => {
                  handleFocus(e, index);
                }}
                onBlur={(e) => {
                  handleBlur(e, index);
                }}
              />
              <span className="absolute left-2 top-3.5 text-primary">
                <LuUser className="w-6 h-6" />
              </span>
              {bookAppointmentFormErrors[index]?.nameError && (
                <p className="text-red-600 pl-2">
                  {bookAppointmentFormErrors[index]?.nameError}
                </p>
              )}
            </div>
            <div className="col-span-12 sm:col-span-6 relative">
              <input
                type="email"
                className="ps-10 pe-4 py-3.5 rounded-lg border-secondaryVariant2 border w-full"
                placeholder="Email"
                name="email"
                value={teacher.email}
                onChange={(e) =>
                  handleInputChange(index, "email", e.target.value)
                }
                onFocus={(e) => {
                  handleFocus(e, index);
                }}
                onBlur={(e) => {
                  handleBlur(e, index);
                }}
              />
              <span className="absolute left-2 top-4 text-primary">
                <FiMail className="w-6 h-6" />
              </span>
              {bookAppointmentFormErrors[index]?.emailError && (
                <p className="text-red-600 pl-2">
                  {bookAppointmentFormErrors[index]?.emailError}
                </p>
              )}
            </div>

            <div className="col-span-12 sm:col-span-6 relative">
              <Select
                className="text-start w-full"
                placeholder="Select a State"
                isClearable
                options={stateOptions}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? "grey" : "#CED2DA",
                    padding: "8px 0 8px 30px",
                    borderRadius: "8px",
                  }),
                }}
                value={stateOptions.find((opt) => opt.value === teacher.state)}
                onChange={(e) =>
                  handleInputChange(index, "state", e?.value as string)
                }
              />
              <span className="absolute left-2 top-4 text-primary">
                <img
                  src="../images/MedEvaluation_images/globe-05.svg"
                  alt="globe"
                />
              </span>
            </div>
            <div className="col-span-12 sm:col-span-6 relative">
              <Select
                className="text-start w-full"
                placeholder="Select a Service"
                isClearable
                options={serviceOptions}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? "grey" : "#CED2DA",
                    padding: "8px 0 8px 30px",
                    borderRadius: "8px",
                  }),
                }}
                value={serviceOptions.find(
                  (opt) => opt.value === teacher.service
                )}
                onChange={(e) =>
                  handleInputChange(index, "service", e?.value as string)
                }
              />
              <span className="absolute left-2 top-4 text-primary">
                <LuHeartPulse className="w-6 h-6" />
              </span>
            </div>
            <div className="col-span-12 sm:col-span-6 relative">
              <DropdownMenu.Root
                open={isOpenArray[index]}
                onOpenChange={(isOpen) => handleToggleCalendar(index, isOpen)}
              >
                <DropdownMenu.Trigger
                  className={`relative border pe-2 ps-9 py-3 min-h-[54px] w-full h-full font-inter border-[#CED2DA] text-left ${
                    teacher.dateTime ? "text-secondary" : "text-[#808080]"
                  } rounded-lg flex items-center justify-between`}
                >
                  {teacher.dateTime || "Select Date/Time"}{" "}
                  <HiChevronDown size={24} />
                </DropdownMenu.Trigger>
                <DropdownMenu.Content
                  className="absolute top-2 right-0 w-[355px] shadow-md p-4 rounded-lg border border-secondaryVariant2/50 px-4 pb-6 bg-white z-20"
                  side="right"
                  align="end"
                >
                  <Calendar
                    onDateTimeSelect={(date, time) =>
                      handleDateTimeSelection(index, date, time)
                    }
                    unavailableDateTimes={unavailableDateTimes}
                  />
                </DropdownMenu.Content>
              </DropdownMenu.Root>
              <span className="absolute left-2 top-4 text-primary">
                <TbCalendarHeart className="w-6 h-6" />
              </span>
            </div>
            {teachers?.length > 1 && (
              <div className="col-span-12 sm:col-span-6 xl:col-span-4 my-auto ">
                <button
                  className="text-red-500 hover:text-red-700 "
                  onClick={() => handleRemoveTeacher(index)}
                >
                  <CiCircleMinus className="w-7 h-7" />
                </button>
              </div>
            )}
          </div>
        ))}
        {/* <button
          onClick={handleAddTeacher}
          className="btnSecondary max-w-[233px] mb-4"
        >
          Add Teacher
        </button> */}
        <div className="flex items-center gap-6">
          <button
            onClick={handleBookAppointment}
            className={`btnPrimary max-w-[250px] gap-1 ${
              !isFormComplete ? "opacity-50" : ""
            }`}
            disabled={!isFormComplete}
          >
            <CiCalendar className="w-5 h-5" />
            Book Appointment
          </button>
          <strong>OR</strong>
          <button
            onClick={handleAddTeacher}
            className="w-full max-w-[170px] flex items-center justify-center gap-1 text-secondary border border-secondary text-lg font-medium rounded-lg py-2.5 px-4"
          >
            <CiSquarePlus className="w-5 h-5" />
            Add Teacher
          </button>
        </div>
        {showBeforeLoginModal && (
          <BeforeLoginModal
            handleBeforeLoginModal={handleOpenBeforeLoginModal}
            onClose={handleCloseBeforeLoginModal}
          />
        )}
      </div>
    </>
  );
};

export default CenterBookingSelector;

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BlogCard from "../../../medEvaluation_module/components/blogCard/BlogCard";
import { blogSvc } from "../../services/blogs.service";
import { IBlog } from "../../../medEvaluation_module/types/blog.interface";
import { CiSearch } from "react-icons/ci";
import { sharedRoutes } from "../../routes/routes";
import { debounce } from "lodash";
import ButtonGroup from "../ui/ButtonGroup";
import Icons from "../../../medEvaluation_module/components/icon/Icons";

interface Props {
  scrollUp?: boolean;
  setScrollUp?: React.Dispatch<React.SetStateAction<boolean>>;
  setTrendingBlog?: (blog: IBlog) => void;
  hideButtons?: boolean;
}
const BlogCards: React.FC<Props> = ({
  scrollUp,
  setScrollUp,
  setTrendingBlog,
  hideButtons
}) => {
  const [blogs, setBlogs] = useState<IBlog[]>([]);
  const [selectedButton, setSelectedButton] = useState("center");
  const [blogCountToShow, setBlogsCountToShow] = useState(6);
  const [totalBlogs, setTotalBlogs] = useState(0);
  const pathName = useLocation().pathname;
  const navigate = useNavigate();

  const fetchBlogs = async (category = "center", title = "", limit = 6) => {
    const resp = await blogSvc.getBlogs(category, title, limit);
    setBlogs(resp?.data);
    if (resp?.data?.length > 0) {
      const latestBlog = resp?.data?.reduce((latest: IBlog, current: IBlog) => {
        return new Date(latest?.pubDate) > new Date(current?.pubDate)
          ? latest
          : current;
      });
      setTrendingBlog && setTrendingBlog(latestBlog);
    }
    setTotalBlogs(resp?.totalBlogs);
  };

  useEffect(() => {
    fetchBlogs(selectedButton, "", blogCountToShow);
  }, [blogCountToShow, selectedButton]);

  const handleViewMore = () => {
    setBlogsCountToShow((prevCount) => prevCount + 6);
  };

  const handleButtonClick = (buttonName: string) => {
    setSelectedButton(buttonName);
  };

  const navigateToBlogs = () => {
    navigate(sharedRoutes.blogs_screen);
  };

  const handleReadMore = (blog: IBlog) => {
    setScrollUp && setScrollUp(!scrollUp);
    navigate(sharedRoutes.blogs_details, { state: { blog } });
  };

  const handleNumberOfBlogsCardToShow = () => {
    return pathName === sharedRoutes?.blogs_screen
      ? blogCountToShow
      : 3;
  };

  const handleSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    fetchBlogs(selectedButton, e.target.value, blogCountToShow);
  }, 300);

  useEffect(() => {
    if (pathName.includes("teacher")) {
      setSelectedButton("teacher");
    } else if (pathName.includes("parent")) {
      setSelectedButton("parent");
    } else {
      setSelectedButton("center");
    }
  }, [pathName]);

  const getIcon = (option: string, isSelected: boolean) => {
    const iconColor = isSelected ? "#DF7A5E" : "#050B33";
    switch (option) {
      case "center":
        return Icons.BuildingIcon(iconColor, 24, 24);
      case "teacher":
        return Icons.User(iconColor, 24, 24);
      case "parent":
        return Icons.Users(iconColor, 24, 24);
      default:
        return null;
    }
  };

  return (
    <section className="pb-4 md:pb-10 xl:pb-16">
      <div className="container ">
        <div className="flex justify-between items-center gap-2 mb-4 md:mb-10 xl:mb-[36px]">
          <h2 className="text-secondary font-semibold text-2xl md:text-xll xl:text-[28px]">
            Blogs
          </h2>
          {pathName === sharedRoutes.blogs_screen ? (
            <div className="relative">
              <input
                className="rounded-lg border-[0.5px] border-secondaryVariant2 py-2 md:py-3.5 ps-6 md:ps-10 pe-1 md:pe-3 max-w-[190px] md:max-w-[270px]"
                type="search"
                placeholder="Search for articles"
                onChange={handleSearch}
              />
              <span className="absolute top-2.5 md:top-3.5 md:left-2 left-1">
                <CiSearch className="w-5 md:w-6 h-5 md:h-6 text-secondaryVariant2" />
              </span>
            </div>
          ) : (
            <button
              className="text-lg text-statusColor font-medium"
              onClick={navigateToBlogs}
            >
              View all
            </button>
          )}
        </div>
        {!hideButtons &&
          <ButtonGroup
            options={["center", "teacher", "parent"]}
            selectedButton={selectedButton}
            getIcon={getIcon}
            handleButtonClick={handleButtonClick}
          />
        }
        <div className="grid grid-cols-12 gap-6 xl:gap-9 mb-9 md:mb-10 xl:mb-16 mt-8">
          {blogs &&
            blogs
              ?.slice(0, handleNumberOfBlogsCardToShow())
              ?.map((blog, index) => (
                <React.Fragment key={index}>
                  <BlogCard
                    selectedCategory={selectedButton}
                    blog={blog}
                    handleReadMore={handleReadMore}
                  />
                </React.Fragment>
              ))}
        </div>

        {blogs &&
          blogs?.length > 0 &&
          blogCountToShow < totalBlogs &&
          pathName === sharedRoutes?.blogs_screen && (
            <div className="text-center">
              <button
                className="text-lg text-statusColor font-medium"
                onClick={handleViewMore}
              >
                View more
              </button>
            </div>
          )}
      </div>
    </section>
  );
};

export default BlogCards;
